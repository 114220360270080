import { boolean, maybe, optional, text } from '@fmtk/decoders';
import { PhotoCamera, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { DealerUser } from '../../../../api/UserService/listUsersByDealer/ListDealerUsersResponse.js';
import { RegisterUserCode } from '../../../../api/UserService/registerUser/RegisterUserResponse.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';

import { t } from 'i18next';
import { Policy } from '../../../../api/util/Policy.js';
import { convertToString } from '../../../../core/DbService/util/convertToString.js';
import { AvatarPicker } from '../../../common-ui/components/AvatarPicker.js';
import {
  ErrorState,
  Form,
  FormTextField,
  Message,
  Switch,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { sx } from '../../../util/sx.js';

interface DealerUserFormProps {
  user?: DealerUser;
  onSave: (user: DealerUser) => void;
  dealerId: number;
  brand: AvailableBrands;
}

const styles = sx({
  noImageAvatarComponent: {
    width: '140px',
    height: '140px',
    borderRadius: '5px',
  },
});

const DealerUserForm: FunctionComponent<DealerUserFormProps> = ({
  user,
  onSave,
  dealerId,
  brand,
}) => {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const { value: currentUser } = useCurrentUser();

  const form = useCreateForm(
    {
      avatarUrl: maybe(text),
      email: text,
      familyName: text,
      givenName: text,
      hasRemarketing: optional(boolean),
      isAdmin: optional(boolean),
    },
    async (value) => {
      const dealerIdString = convertToString(dealerId);

      if (!dealerIdString) {
        throw new Error('Dealer ID is not a string');
      }

      if (user) {
        const newUser = await api.user.updateDealerUser({
          dealerId,
          brand,
          user,
        });
        onSave(newUser);
        return;
      }

      const policy: Policy = {
        Admin: value.isAdmin ? [dealerIdString] : [],
        API: undefined,
        Basic: true,
        Brand: [brand],
        Dealer: [dealerIdString],
        DealerReporting: [brand],
        GlobalReporting: [],
        Region: [],
        RemarketingDealer: value.hasRemarketing ? [dealerIdString] : [],
        Support: undefined,
        User: undefined,
        VehicleEditor: [brand],
        VehicleViewer: [brand],
      };

      const response = await api.user.registerUser({
        ...value,
        policy,
        dealerId,
        brand,
      });

      let message: Message = {
        text: t('pages.users.userCreatedSuccessfully'),
        severity: 'success',
        dismissible: true,
        duration: 10000,
      };

      if (response.code === RegisterUserCode.AlreadyExists) {
        message = {
          text: t('pages.users.givenNameExists'),
          severity: 'info',
          dismissible: true,
          duration: 10000,
        };
      }
      if (response.code === RegisterUserCode.Imported) {
        message = {
          text: t('pages.users.userExistsInSA'),
          severity: 'info',
          dismissible: true,
          duration: 10000,
        };
      }
      if (response.code === RegisterUserCode.AlreadyExistsInDealer) {
        message = {
          text: t('pages.users.userExistsInDealer'),
          severity: 'warning',
          dismissible: true,
        };
      }

      showMessage(message);
      onSave(response.user);
    },
    undefined,
    [user, api],
  );
  const [formState, formBind] = form;

  function handleFileChange(image: string | undefined) {
    formBind.setValue('avatarUrl', image);
  }

  useEffect(() => {
    formBind.reset({
      avatarUrl: user?.avatarUrl || '',
      email: user?.email || '',
      familyName: user?.familyName || '',
      givenName: user?.givenName || '',
      hasRemarketing: user?.hasRemarketing || false,
      isAdmin: user?.isAdmin || false,
    });
  }, [formBind, user]);

  return (
    <Box>
      <Form form={form}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Stack
                alignItems="flex-star"
                justifyContent="space-between"
                spacing={{ xs: 4, sm: 6 }}
              >
                <Stack flex={1} spacing={1.5} width="100%">
                  <Box maxWidth={300}>
                    <AvatarPicker
                      avatarFallbackComponent={
                        <Avatar
                          sx={styles.noImageAvatarComponent}
                          variant="rounded"
                        >
                          {user ? (
                            <Typography fontSize="40px">
                              {`${formState.values.givenName[0]}${formState.values.familyName[0]}`}
                            </Typography>
                          ) : (
                            <PhotoCamera fontSize="large" />
                          )}
                        </Avatar>
                      }
                      avatarOptions={{
                        width: 140,
                        height: 140,
                        border: 10,
                        borderRadius: 70,
                        color: [255, 255, 255, 0.8],
                      }}
                      onSelectFile={(file) => handleFileChange(file)}
                      source={formState.values.avatarUrl}
                    />
                  </Box>
                  <FormTextField
                    label={t('pages.users.givenName')}
                    name="givenName"
                    placeholder="Jane"
                  />
                  <FormTextField
                    label={t('pages.users.familyName')}
                    name="familyName"
                    placeholder="Smith"
                  />
                  <FormTextField
                    disabled={Boolean(user)}
                    label={t('pages.users.email')}
                    name="email"
                    placeholder="jane.smith@gmail.com"
                    readOnly={Boolean(user)}
                    type="email"
                  />
                </Stack>

                <FormControlLabel
                  control={
                    <Box pr={2}>
                      <Switch
                        checked={Boolean(formState.values.isAdmin)}
                        disabled={
                          user?.principalId === currentUser?.principalId
                        }
                        onChange={(_, checked) => {
                          formBind.setValue('isAdmin', checked);
                        }}
                      />
                    </Box>
                  }
                  label={t('pages.users.admin')}
                />
                <FormControlLabel
                  control={
                    <Box pr={2}>
                      <Switch
                        checked={Boolean(formState.values.hasRemarketing)}
                        disabled={
                          user?.principalId === currentUser?.principalId
                        }
                        onChange={(_, checked) => {
                          formBind.setValue('hasRemarketing', checked);
                        }}
                      />
                    </Box>
                  }
                  label={t('pages.users.remarketing')}
                />
              </Stack>
              <Box mt={1}>
                {formState.submitError && <ErrorState hideTitle />}
              </Box>
            </CardContent>
            <CardActions>
              <Box m={1} marginLeft="auto">
                <Stack direction="row" justifyContent="end" spacing={1}>
                  <LoadingButton
                    endIcon={<Save />}
                    loading={formState.busy}
                    loadingPosition="end"
                    type="submit"
                    variant="contained"
                  >
                    {t('pages.users.save')}
                  </LoadingButton>
                </Stack>
              </Box>
            </CardActions>
          </Card>
        </Stack>
      </Form>
    </Box>
  );
};

export default DealerUserForm;
