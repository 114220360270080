import { Button, Link, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ListingStatus, Vehicle } from '../../../../../api/util/Vehicle.js';
import { normalizeVIN } from '../../../../../core/DbService/util/normalizeVIN.js';
import SearchInput from '../../../../common-ui/components/Filters/SearchInput.js';
import TableOfContents, {
  ContentItem,
} from '../../../../common-ui/components/TableOfContents.js';
import { ErrorState } from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { useDealer } from '../../../../hooks/useDealer.js';
import { useMobile } from '../../../../hooks/useMobile.js';
import { mapBrandCertifiedDisplayName } from '../../../../util/vehicleTypes.js';
import { MAIN_CONTAINER_ID } from '../../../layout/MainLayout.js';
import { addVehicleStepperAtom } from '../../../state/atoms.js';
import Section from '../../Section.js';
import VehicleDetailsItem from '../VehicleDetailsItem.js';
import VehicleDetailsItemSkeleton from '../VehicleDetailsItemSkeleton.js';

export enum MessageType {
  NotFound = 'notFound',
  InOtherDealer = 'inOtherDealer',
  Added = 'added',
}

const VinStep: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const mobile = useMobile();
  const [searchValue, setSearchValue] = useState<string>('');
  const [vehicle, setVehicle] = useAsyncState<Vehicle>();
  const [messageType, setMessageType] = useState<MessageType>();
  const api = useApiClient();
  const { currentBrand, brandConfig } = useBrand();
  const { currentDealer } = useDealer();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { brand, dealer } = useParams();
  const [addVehicleState, setAddVehicleState] = useAtom(addVehicleStepperAtom);

  const contentItems: ContentItem[] = [
    {
      text: t('pages.vehicleAddPage.vin.search'),
      hash: 'search',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehicleAddPage.vin.details'),
      hash: 'details',
      sectionElementRef: useRef(null),
    },
  ];

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(normalizeVIN(event.target.value) || '');
  };

  const handleSearch = (event: KeyboardEvent<HTMLDivElement>) => {
    if (searchValue !== '' && event.key === 'Enter' && currentBrand) {
      setVehicle(async () => {
        const response = await api.vehicleListing.getVehicleByVin({
          vin: searchValue,
          brand: currentBrand,
          country: currentDealer?.countryCode,
        });

        const listing = await api.vehicleListing.getVehicleListingByVin({
          vin: searchValue,
        });

        setMessageType(() => {
          if (!response) {
            return MessageType.NotFound;
          } else if (!listing) {
            return undefined;
          } else if (listing.dealer.id === currentDealer?.id) {
            return MessageType.Added;
          } else {
            return MessageType.InOtherDealer;
          }
        });
        setAddVehicleState((state) => ({
          ...state,
          temporaryVin: searchValue,
          currentListing: undefined,
        }));

        return response;
      });
    }
  };

  const handleSelect = () => {
    const ele = document.getElementById(MAIN_CONTAINER_ID);
    if (ele) {
      ele.scrollTo(0, 0);
    }

    if (!selectedVehicle || !currentDealer) {
      return;
    }

    setAddVehicleState((state) => {
      return {
        ...state,
        temporaryVin: '',
        currentListing: {
          ...state.currentListing,
          listingStatus: ListingStatus.ForSale,
          stockDate: new Date(),
          vehicle: selectedVehicle,
          dealer: { id: Number(currentDealer.id) },
        },
      };
    });

    navigate(`${pathname}/details`);
  };

  const handleReset = () => {
    setSearchValue('');
  };

  const selectedVehicle = useMemo(
    () => vehicle.value || addVehicleState.currentListing?.vehicle,
    [vehicle.value, addVehicleState],
  );

  const canSelect = selectedVehicle && messageType === undefined;

  useEffect(() => {
    setSearchValue(
      addVehicleState.temporaryVin ||
        addVehicleState.currentListing?.vehicle.vin ||
        '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addVehicleState]);

  return (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section
          title={
            <Stack direction="row" justifyContent="space-between">
              <Typography textTransform="uppercase" variant="subtitle1">
                {t('vinNumber')}
              </Typography>
              {mobile && (
                <Link
                  component={RouterLink}
                  to={`/${brand}/dealers/${dealer}/dashboard`}
                  variant="button"
                >
                  {t('cancel')}
                </Link>
              )}
            </Stack>
          }
        >
          <div
            id={contentItems[0].hash}
            ref={
              contentItems[0]
                .sectionElementRef as React.RefObject<HTMLDivElement>
            }
          >
            <Stack mt={2} spacing={1.5}>
              <Stack
                direction={{
                  sm: 'column-reverse',
                  md: 'row',
                }}
                flex={1}
                justifyContent="space-between"
              >
                <Typography color="grey" variant="subtitle2">
                  {t('pages.vehicleAddPage.vin.searchDescription')}
                </Typography>
                {currentBrand && brandConfig?.dealerTNCLink && (
                  <Link
                    color="primary"
                    href={brandConfig.dealerTNCLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="subtitle2"
                  >
                    {`${mapBrandCertifiedDisplayName[currentBrand]} ${t(
                      'pages.vehicleAddPage.vin.DealerTermsAndConditionsLink',
                    )}`}
                  </Link>
                )}
              </Stack>
              <SearchInput
                error={messageType === MessageType.NotFound}
                fullWidth
                loading={vehicle.loading}
                onChange={handleInputChange}
                onKeyDown={handleSearch}
                onResetClick={handleReset}
                placeholder={t('pages.vehicleAddPage.vin.searchPlaceholder')}
                size="small"
                value={searchValue}
              />
              {messageType && (
                <Typography
                  color="error"
                  fontWeight="medium"
                  variant="subtitle2"
                >
                  {t(`pages.vehicleAddPage.vin.${messageType}`)}
                </Typography>
              )}
              {messageType === MessageType.NotFound &&
                brandConfig?.addListing?.allowAddManually && (
                  <Typography
                    color="primary"
                    component={RouterLink}
                    to="manually"
                  >
                    {t('pages.vehicleAddPage.vin.addManually')}
                  </Typography>
                )}
            </Stack>
          </div>
          <Stack my={3}>
            {vehicle.error ? (
              <ErrorState />
            ) : vehicle.loading ? (
              <VehicleDetailsItemSkeleton />
            ) : (
              canSelect && (
                <VehicleDetailsItem
                  contentItem={contentItems[1]}
                  vehicle={selectedVehicle}
                />
              )
            )}
          </Stack>
          {canSelect && (
            <Stack direction="row-reverse">
              <Button
                disabled={vehicle.loading}
                onClick={handleSelect}
                variant="contained"
              >
                {t('select')}
              </Button>
            </Stack>
          )}
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
    </Stack>
  );
};

export default VinStep;
