import { Box, Link, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseScrollSpyParams, useScrollSpy } from '../../hooks/useScrollSpy.js';
import { filterFalsey } from '../../util/filterFalsey.js';
import { sx } from '../../util/sx.js';

export interface ContentItem {
  sectionElementRef: React.RefObject<HTMLElement>;
  text: string;
  hash: string;
}

interface TableOfContentsProps
  extends Omit<UseScrollSpyParams, 'sectionElementRefs'> {
  scrollingElementSelector?: string;
  items: ContentItem[];
}

export const styles = sx({
  root: {
    flexShrink: 0,
    overflowY: 'auto',
    minWidth: 200,
  },
  contents: {
    color: 'grey.600',
    fontWeight: 600,
    marginBottom: 2,
    textTransform: 'uppercase',
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  item: (theme) => ({
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '17px',
    padding: theme.spacing(1, 1),
    boxSizing: 'content-box',
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      color: theme.palette.grey[500],
    },
  }),
  active: (theme) => ({
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
  }),
});

const TableOfContents: FunctionComponent<TableOfContentsProps> = ({
  scrollingElementSelector,
  scrollingElement,
  items,
  ...props
}) => {
  const { t } = useTranslation();
  const rootEl = useRef<HTMLElement | null>(null);
  const [active, setActive] = useState<number>();
  const clickedRef = useRef<boolean>(false);
  const unsetClickedRef = useRef<NodeJS.Timeout>();
  const activeSection = useScrollSpy({
    scrollingElement: rootEl || scrollingElement,
    sectionElementRefs: items.map((section) => section.sectionElementRef) || [],
    ...props,
  });

  const handleClick = (index: number, section: ContentItem) => {
    // Used to disable scrollSpy if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    setActive(index);
  };

  useEffect(() => {
    if (!scrollingElementSelector) {
      return;
    }
    const ele = document.getElementById(scrollingElementSelector);

    if (ele) {
      rootEl.current = ele as HTMLElement;
    }
  }, [scrollingElementSelector]);

  useEffect(() => {
    if (clickedRef.current) {
      return;
    }

    setActive(activeSection);
  }, [activeSection]);

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    [],
  );

  const itemLink = (item: ContentItem, index: number) => {
    const isActive = index === active;
    return (
      <Link
        color={isActive ? 'primary.main' : 'grey.600'}
        display="block"
        href={`#${item.hash}`}
        onClick={(_) => handleClick(index, item)}
        sx={filterFalsey([styles.item, isActive ? styles.active : undefined])}
        underline="none"
      >
        <span dangerouslySetInnerHTML={{ __html: String(item.text) }} />
      </Link>
    );
  };

  return (
    <>
      <Box aria-label="Page table of contents" component="nav" sx={styles.root}>
        {items.length > 0 ? (
          <>
            <Typography gutterBottom sx={styles.contents}>
              {t('tableOfContents')}
            </Typography>
            <Typography component="ul" sx={styles.ul}>
              {items.map((item, index) => (
                <li key={index}>{itemLink(item, index)}</li>
              ))}
            </Typography>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default TableOfContents;
