import { MappingPropertyBase } from '@opensearch-project/opensearch/api/types';

export const getDealerIndexMapping = (): MappingPropertyBase => ({
  dynamic: false,
  properties: {
    type: {
      type: 'text',
      fields: {
        keyword: {
          type: 'keyword',
          ignore_above: 256,
        },
      },
    },
    timestamp: {
      type: 'date',
    },
    brand: {
      type: 'text',
      fields: {
        keyword: {
          type: 'keyword',
          ignore_above: 256,
        },
      },
    },
    markets: {
      type: 'text',
      fields: {
        keyword: {
          type: 'keyword',
          ignore_above: 256,
        },
      },
    },
    address: {
      type: 'text',
    },
    country: {
      fields: {
        keyword: {
          type: 'keyword',
          ignore_above: 256,
        },
      },
      type: 'text',
    },
    dealerLookup: {
      type: 'flat_object',
    },
    email: {
      type: 'text',
    },
    emailPhotos: {
      type: 'text',
    },
    externalId: {
      fields: {
        keyword: {
          type: 'keyword',
          ignore_above: 256,
        },
      },
      type: 'text',
    },
    id: {
      type: 'integer',
    },
    name: {
      type: 'text',
      fields: {
        keyword: {
          type: 'keyword',
          ignore_above: 256,
        },
      },
    },
    phone: {
      type: 'text',
    },
    point: {
      type: 'geo_point',
    },
    region: {
      type: 'object',

      properties: {
        id: {
          type: 'integer',
        },
        name: {
          fields: {
            keyword: {
              type: 'keyword',
              ignore_above: 256,
            },
          },
          type: 'text',
        },
        subregionId: {
          type: 'integer',
        },
        subregion: {
          fields: {
            keyword: {
              type: 'keyword',
              ignore_above: 256,
            },
          },
          type: 'text',
        },
      },
    },
    website: {
      type: 'text',
    },
  },
});
