import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback } from 'react';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { monthNames } from '../../../../../api/util/dates.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import {
  Cell,
  CellDateInput,
  CellSelectInput,
  CellTextInput,
  useForm,
} from '../../../../common-ui/index.js';
import { useVehicle } from '../../../../hooks/useVehicle.js';
import { Option } from '../../../../util/Option.js';
import { VehicleFields } from '../../../../util/VehicleFields.js';
import { ActionType, Section } from './types.js';

interface KeyInformationProps extends Omit<Section, 'vehicleListing'> {
  showCollection?: boolean;
  modelGroupOptions?: Option[];
  modelOptions?: Option[];
  bodyStyleOptions?: Option[];
  driveOptions?: Option[];
  onChangeModelGroup?: (option?: Option) => void;
  allowChanges?: boolean;
}

const KeyInformation: FunctionComponent<KeyInformationProps> = ({
  title,
  showCollection,
  sectionItemsProps,
  readonly,
  modelGroupOptions,
  modelOptions,
  bodyStyleOptions,
  driveOptions,
  onChangeModelGroup,
  actionType = ActionType.EDIT,
  allowChanges,
}) => {
  const { fieldsConfig, resolveReadonly } = useVehicle();

  const [formState, formBind] = useForm();
  const type = formState.values['syndicationType'];
  const registrationMonth = formState.values['registrationMonth'];
  const registrationYear = formState.values['registrationYear'];

  const handleRegistrationDateChange = useCallback(
    (value?: string) => {
      const monthShort =
        value && DateTime.fromISO(value).isValid
          ? monthNames[DateTime.fromISO(value).month - 1]
          : undefined;
      formBind.setValue('registrationMonth', monthShort || '');
    },
    [formBind],
  );

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack sx={sectionStyles.sectionContent}>
        <Stack flex={1}>
          <Cell label="VIN" name="vin" />
          <CellSelectInput
            label="groupName"
            name="groupName"
            onChange={onChangeModelGroup}
            options={modelGroupOptions || []}
            readonly={readonly}
          />
          <CellSelectInput
            label="Model"
            name="model"
            options={modelOptions || []}
            readonly={readonly}
          />
          {fieldsConfig.variant?.visible && (
            <CellTextInput
              label="variant"
              name="variant"
              readonly={resolveReadonly(
                VehicleFields.Variant,
                actionType,
                readonly,
              )}
            />
          )}
          <CellSelectInput
            label="bodyStyle"
            name="bodyStyle"
            options={bodyStyleOptions || []}
            readonly={readonly}
          />
        </Stack>
        <Stack flex={1}>
          <CellSelectInput
            label="drive"
            name="drive"
            options={driveOptions || []}
            readonly={readonly}
          />
          {showCollection && <Cell label="collection" name="collection" />}
          {type !== SyndicationType.New && (
            <CellDateInput
              formattedValue={!registrationMonth ? registrationYear : undefined}
              label="registrationDate"
              name="registrationDate"
              onChange={handleRegistrationDateChange}
              readonly={
                resolveReadonly(
                  VehicleFields.RegistrationDate,
                  actionType,
                  readonly,
                ) && !allowChanges
              }
            />
          )}
          <CellTextInput
            label="modelYear"
            maxLength={4}
            name="modelYear"
            readonly={readonly}
          />
          {fieldsConfig.productionDate?.visible && (
            <CellDateInput
              label="productionDate"
              name="productionDate"
              readonly={resolveReadonly(
                VehicleFields.ProductionDate,
                actionType,
                readonly,
              )}
            />
          )}
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default KeyInformation;
