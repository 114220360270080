import { MappingPropertyBase } from '@opensearch-project/opensearch/api/types';
import { getDealerIndexMapping } from './getDealerIndexMapping.js';

export const getVehicleListingViewIndexMapping = (): MappingPropertyBase => {
  const dealerMapping = getDealerIndexMapping();

  return {
    dynamic: false,
    properties: {
      analyticsEvent: {
        properties: {
          Compare: {
            type: 'integer',
          },
          Enquire: {
            type: 'integer',
          },
          Print: {
            type: 'integer',
          },
          Shortlist: {
            type: 'integer',
          },
          View: {
            type: 'integer',
          },
        },
      },
      createdDate: {
        type: 'date',
      },
      currency: {
        properties: {
          code: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
        },
      },
      damageStatus: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      dealer: {
        type: 'object',
        ...dealerMapping,
      },
      docType: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      externalListingId: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      id: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      internalComments: {
        index: false,
        type: 'text',
      },
      isLive: {
        type: 'boolean',
      },
      lastUpdatedByUserPrincipalId: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      lastUpdatedDate: {
        type: 'date',
      },
      listingImport: {
        properties: {
          feedId: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          lastSyncDate: {
            type: 'date',
          },
          type: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
        },
      },
      listingStatus: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      liveStatus: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      odometer: {
        properties: {
          odometerOnApplication: {
            type: 'boolean',
          },
          units: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          value: {
            type: 'integer',
          },
        },
      },
      previousOwners: {
        type: 'long',
      },
      price: {
        properties: {
          basePrice: {
            type: 'long',
          },
          german25aTaxSalesDisclaimer: {
            type: 'boolean',
          },
          lessTaxes: {
            type: 'long',
          },
          netPrice: {
            type: 'long',
          },
          priceExcludesVat: {
            type: 'boolean',
          },
          priceOnApplication: {
            type: 'boolean',
          },
          retail: {
            type: 'long',
          },
          retailPlusTax: {
            type: 'long',
          },
          standInValue: {
            type: 'long',
          },
          trade: {
            type: 'long',
          },
          vatQualifying: {
            type: 'boolean',
          },
        },
      },
      publicComments: {
        index: false,
        type: 'text',
      },
      registrationPlate: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      stockDate: {
        type: 'date',
      },
      syndicationType: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      timestamp: {
        type: 'date',
      },
      urlVehicleDetailPage: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      vehicle: {
        properties: {
          appearanceOptions: {
            properties: {
              carpetColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              exteriorColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              exteriorMetaColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              headliningColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              interiorColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              interiorMetaColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              seatColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              secondaryExteriorColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              secondaryInteriorColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              steeringWheelColour: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              veneer: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
            },
          },
          bodyStyle: {
            properties: {
              id: {
                type: 'integer',
              },
              metaName: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              name: {
                type: 'text',
              },
            },
          },
          brand: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          brandSpecific: {
            properties: {
              'aston-martin': {
                properties: {
                  bodystyleDescription: {
                    fields: {
                      keyword: {
                        ignore_above: 256,
                        type: 'keyword',
                      },
                    },
                    type: 'text',
                  },
                  isHeritage: {
                    type: 'boolean',
                  },
                  isQ: {
                    type: 'boolean',
                  },
                  isSpecial: {
                    type: 'boolean',
                  },
                },
              },
              kia: {
                properties: {
                  engine: {
                    fields: {
                      keyword: {
                        ignore_above: 256,
                        type: 'keyword',
                      },
                    },
                    type: 'text',
                  },
                },
              },
              lamborghini: {
                properties: {
                  lfsModelCode: {
                    fields: {
                      keyword: {
                        ignore_above: 256,
                        type: 'keyword',
                      },
                    },
                    type: 'text',
                  },
                },
              },
              'rolls-royce': {
                properties: {
                  collection: {
                    fields: {
                      keyword: {
                        ignore_above: 256,
                        type: 'keyword',
                      },
                    },
                    type: 'text',
                  },
                },
              },
            },
          },
          damageStatus: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          engine: {
            properties: {
              bhp: {
                type: 'text',
              },
              capacity: {
                type: 'integer',
              },
              cylinders: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              description: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              fuelType: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              id: {
                type: 'integer',
              },
              powerKw: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              torqueLbft: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              torqueNm: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
            },
          },
          environmentalLabel: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          euroEmissionsClass: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          externalListingId: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          features: {
            type: 'flat_object',
          },
          handDrive: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          model: {
            properties: {
              capCode: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              capId: {
                type: 'integer',
              },
              id: {
                type: 'integer',
              },
              name: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
            },
          },
          modelYear: {
            type: 'short',
          },
          nedcTest: {
            properties: {
              nedcCo2Emissions: {
                type: 'float',
              },
              nedcCombinedFuelEconomy: {
                type: 'float',
              },
              nedcExtraUrbanFuelEconomy: {
                type: 'float',
              },
              nedcUrbanFuelEconomy: {
                type: 'float',
              },
            },
          },
          previousOwners: {
            type: 'long',
          },
          productionDate: {
            type: 'date',
          },
          registrationDate: {
            type: 'date',
          },
          registrationMonth: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          registrationYear: {
            type: 'short',
          },
          rrp: {
            properties: {
              currency: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              value: {
                type: 'long',
              },
            },
          },
          techSpecs: {
            properties: {
              acceleration060Mph: {
                type: 'text',
              },
              acceleration0100Kph: {
                type: 'text',
              },
              height: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              kerbWeight: {
                type: 'text',
              },
              length: {
                type: 'text',
              },
              maxSpeedKph: {
                type: 'text',
              },
              maxSpeedMph: {
                type: 'text',
              },
              width: {
                type: 'text',
              },
            },
          },
          transmission: {
            properties: {
              id: {
                type: 'integer',
              },
              metaName: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              name: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
            },
          },
          variant: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          vin: {
            fields: {
              keyword: {
                ignore_above: 256,
                type: 'keyword',
              },
            },
            type: 'text',
          },
          warrantyExpiryDate: {
            type: 'date',
          },
          wltpTest: {
            properties: {
              batteryRange: {
                type: 'long',
              },
              batteryRangeCity: {
                type: 'long',
              },
              co2ClassDischargedBattery: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              co2ClassWeightedCombined: {
                fields: {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                },
                type: 'text',
              },
              co2Emissions: {
                type: 'float',
              },
              comsumptionCombinedDischargedBattery: {
                type: 'float',
              },
              consumptionCombined: {
                type: 'float',
              },
              consumptionCombinedWeighted: {
                type: 'float',
              },
              consumptionExtraHigh: {
                type: 'float',
              },
              consumptionHigh: {
                type: 'float',
              },
              consumptionLow: {
                type: 'float',
              },
              consumptionMid: {
                type: 'float',
              },
              electricityConsumption: {
                type: 'float',
              },
              powerConsumptionCombined: {
                type: 'float',
              },
              powerConsumptionCombinedWeighted: {
                type: 'long',
              },
              weightedCombined: {
                type: 'float',
              },
            },
          },
        },
      },
      vehicleLocale: {
        type: 'flat_object',
      },
      vehicleMedia: {
        type: 'flat_object',
      },
      vinMd5Hash: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      visibility: {
        fields: {
          keyword: {
            ignore_above: 256,
            type: 'keyword',
          },
        },
        type: 'text',
      },
      youTubeLink: {
        index: false,
        type: 'text',
      },
    },
  };
};
