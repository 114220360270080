export enum DealerTypes {
  Archived = 'ARCHIVED',
  Disabled = 'DISABLED',
  Franchised = 'FRANCHISED',
  HeadOffice = 'HEAD_OFFICE',
  Historic = 'HISTORIC',
  Lapsed = 'LAPSED',
  Locator = 'LOCATOR',
  NotLiveYet = 'NOTLIVEYET',
  Other = 'OTHER',
  Satellite = 'SATELLITE',
}
