import {
  UndefinedFields,
  array,
  boolean,
  chain,
  enumValue,
  jsonDate,
  maybe,
  number,
  object,
  optional,
  string,
  text,
} from '@fmtk/decoders';

import {
  UploadMediaRequest,
  decodeUploadMediaRequest,
} from '../../MediaService/uploadMedia/UploadMediaRequest.js';
import { decodeCurrency } from '../../util/Currency.js';
import {
  AvailableBrands,
  BrandSpecific,
  ListingStatus,
  ListingVisibility,
  SyndicationType,
  VehicleAppearanceOptions,
  VehicleFeatures,
  VehicleListing,
  decodeBrandSpecific,
  decodeListingImport,
  decodeVehicleAppearanceOptions,
  decodeVehicleFeatures,
  decodeVehicleOdometer,
  decodeVehiclePrice,
} from '../../util/index.js';
import { isUrl } from '../../util/isUrl.js';

// These fields are the ones that can be set null in the database

export enum UpdateUndefinedFields {
  comments = 'comments',
  publicComments = 'publicComments',
  exteriorColour = 'exteriorColour',
  exteriorMetaColour = 'exteriorMetaColour',
  interiorColour = 'interiorColour',
  netPrice = 'netPrice',
  priceLessTaxes = 'priceLessTaxes',
  basePrice = 'basePrice',
  retailPricePlusTaxes = 'retailPricePlusTaxes',
  regPlate = 'regPlate',
  youTubeLink = 'youTubeLink',
  tradePrice = 'tradePrice',
  carpetColour = 'carpetColour',
  headliningColour = 'headliningColour',
  interiorMetaColour = 'interiorMetaColour',
  seatColour = 'seatColour',
  secondaryExteriorColour = 'secondaryExteriorColour',
  steeringWheelColour = 'steeringWheelColour',
  veneer = 'veneer',
  secondaryInteriorColour = 'secondaryInteriorColour',
}

export type UpdateVehicleListingRequest = Pick<
  VehicleListing,
  | 'currency'
  | 'internalComments'
  | 'listingImport'
  | 'odometer'
  | 'price'
  | 'publicComments'
  | 'registrationPlate'
  | 'syndicationType'
  | 'visibility'
  | 'youTubeLink'
> & {
  id: string;
  brand: AvailableBrands;
  brandSpecific?: BrandSpecific;
  features?: VehicleFeatures;
  options?: {
    updateUndefinedFields?: UpdateUndefinedFields[];
    async?: boolean;
  };
  appearanceOptions?: VehicleAppearanceOptions;
  registrationDate?: Date;
  listingStatus?: ListingStatus;
  capacity?: number;
  uploadMedia?: UploadMediaRequest[];
  registrationMonth?: string;
};

export const decodeUpdateVehicleListingRequest =
  object<UpdateVehicleListingRequest>(
    {
      id: string,
      brand: enumValue(AvailableBrands),
      currency: maybe(decodeCurrency),
      features: optional(decodeVehicleFeatures),
      internalComments: maybe(string),
      listingImport: maybe(decodeListingImport(jsonDate)),
      listingStatus: maybe(enumValue(ListingStatus)),
      odometer: maybe(decodeVehicleOdometer),
      price: maybe(decodeVehiclePrice),
      publicComments: maybe(string),
      registrationPlate: maybe(string),
      syndicationType: maybe(enumValue(SyndicationType)),
      visibility: maybe(enumValue(ListingVisibility)),
      options: maybe(
        object({
          updateUndefinedFields: maybe(array(enumValue(UpdateUndefinedFields))),
          async: maybe(boolean),
        }),
      ),
      brandSpecific: maybe(decodeBrandSpecific),
      youTubeLink: maybe(chain(string, isUrl)),
      appearanceOptions: maybe(decodeVehicleAppearanceOptions),
      registrationDate: maybe(jsonDate),
      capacity: maybe(number),
      uploadMedia: maybe(array(decodeUploadMediaRequest)),
      registrationMonth: maybe(text),
    },
    { undefinedFields: UndefinedFields.Explicit },
  );
