import {
  DecoderError,
  boolean,
  enumValue,
  error,
  jsonDate,
  maybe,
  normaliseNumber,
  number,
  ok,
  strToNum,
  text,
} from '@fmtk/decoders';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import { HandDrive } from '../../../../../api/util/HandDrive.js';
import { OdometerUnit } from '../../../../../api/util/OdometerUnit.js';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import {
  ListingStatus,
  ListingVisibility,
  Vehicle,
  decodeVehicle,
} from '../../../../../api/util/Vehicle.js';
import { normaliseDate } from '../../../../../util/decoders/normaliseDate.js';
import FormErrorsSummary from '../../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../../common-ui/components/LightTooltip.js';
import TableOfContents, {
  ContentItem,
} from '../../../../common-ui/components/TableOfContents.js';
import {
  ConfirmModal,
  Form,
  useMessageStore,
} from '../../../../common-ui/index.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { useCreateForm } from '../../../../hooks/useCreateForm.js';
import { useDealer } from '../../../../hooks/useDealer.js';
import { useSyndicationTypeOptions } from '../../../../hooks/useSyndicationTypeOptions.js';
import { OnboardingSteps } from '../../../../util/index.js';
import { MAIN_CONTAINER_ID } from '../../../layout/MainLayout.js';
import { addVehicleStepperAtom } from '../../../state/atoms.js';
import Section from '../../Section.js';
import { validateSaveVehicleRule } from '../../Vehicle/SaveVehicleRules/validateSaveVehicleRule.js';
import Comments from '../../Vehicle/Sections/Comments.js';
import Listing from '../../Vehicle/Sections/Listing.js';
import Price from '../../Vehicle/Sections/Price.js';
import { ActionType, YesNo } from '../../Vehicle/Sections/types.js';

export type AddVehicleFormState = {
  bodyStyle: string | undefined;
  brand: AvailableBrands;
  capacity: number | undefined;
  collection: string | undefined;
  currency: string | undefined;
  drive: HandDrive | undefined;
  engineType: string | undefined;
  entryDate: Date;
  exteriorColour: string | undefined;
  german25aTaxSalesDisclaimer: YesNo | undefined;
  vatQualifying: YesNo | undefined;
  priceExcludesVat: YesNo | undefined;
  interiorColour: string | undefined;
  secondaryExteriorColour: string | undefined;
  secondaryInteriorColour: string | undefined;
  internalComments: string | undefined;
  listingStatus: ListingStatus;
  model: string;
  modelId: number;
  modelYear: number | undefined;
  netPrice: number | undefined;
  odometer: number | undefined;
  odometerOnApplication: boolean | undefined;
  odometerUnit: OdometerUnit | undefined;
  price: number | undefined;
  basePrice: number | undefined;
  priceLessTaxes: number | undefined;
  priceOnApplication: boolean | undefined;
  publicComments: string | undefined;
  registrationDate: Date | undefined;
  regPlate: string | undefined;
  retailPricePlusTax: number | undefined;
  standInValue: number | undefined;
  syndicationType: SyndicationType;
  trade: number | undefined;
  transmission: string;
  variant: string | undefined;
  vin: string;
  visibility: ListingVisibility;
  vehicle: Vehicle | undefined;
  dealerCountry: string | undefined;
};

const DetailsStep: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const brandSyndicationTypes = useSyndicationTypeOptions();
  const { currentBrand, isEditor, brandConfig, isViewer } = useBrand();
  const { currentDealer } = useDealer();
  const readonly = isViewer && !isEditor;
  const [resetConfirm, setResetConfirm] = useState(false);
  const navigate = useNavigate();
  const { showMessage } = useMessageStore();
  const { brand, dealer } = useParams();
  const [stepperState, setStepperSate] = useAtom(addVehicleStepperAtom);
  const nextStep = useMemo(() => {
    return (
      brandConfig?.addListing?.steps.find(
        (s) => s === OnboardingSteps.Features,
      ) || 'media'
    );
  }, [brandConfig]);

  const form = useCreateForm<AddVehicleFormState, void>(
    {
      bodyStyle: maybe(text),
      brand: enumValue(AvailableBrands),
      capacity: maybe(normaliseNumber),
      collection: maybe(text),
      currency: maybe(text),
      drive: maybe(enumValue(HandDrive)),
      engineType: maybe(text),
      entryDate: normaliseDate(),
      exteriorColour: maybe(text),
      german25aTaxSalesDisclaimer: maybe(enumValue(YesNo)),
      vatQualifying: maybe(enumValue(YesNo)),
      priceExcludesVat: maybe(enumValue(YesNo)),
      interiorColour: maybe(text),
      secondaryExteriorColour: maybe(text),
      secondaryInteriorColour: maybe(text),
      internalComments: maybe(text),
      listingStatus: enumValue(ListingStatus),
      model: text,
      modelId: number,
      modelYear: maybe(normaliseNumber),
      netPrice: maybe(strToNum),
      odometer: maybe(normaliseNumber),
      odometerOnApplication: maybe(boolean),
      odometerUnit: maybe(enumValue(OdometerUnit)),
      price: maybe(strToNum),
      basePrice: maybe(strToNum),
      priceLessTaxes: maybe(strToNum),
      priceOnApplication: maybe(boolean),
      publicComments: maybe(text),
      registrationDate: maybe(normaliseDate()),
      regPlate: maybe(
        text.options({
          maxLength: 10,
        }),
      ),
      retailPricePlusTax: maybe(strToNum),
      standInValue: maybe(strToNum),
      syndicationType: enumValue(SyndicationType),
      trade: maybe(strToNum),
      transmission: text,
      variant: maybe(text),
      vin: text,
      visibility: enumValue(ListingVisibility),
      vehicle: maybe(decodeVehicle(jsonDate)),
      dealerCountry: maybe(text),
    },
    async (values) => {
      if (
        !currentDealer ||
        !currentBrand ||
        currentBrand !== values.brand ||
        !values.vehicle
      ) {
        showMessage({
          severity: 'error',
          text: t('errorOccurredMessage'),
          dismissible: true,
        });
        return;
      }

      setStepperSate((state) => {
        if (!state.currentListing?.dealer.id) {
          return state;
        }

        return {
          ...state,
          detailsFormState: {
            ...state.detailsFormState,
            ...values,
          },
          currentListing: {
            ...state.currentListing,
            listingStatus: values.listingStatus,
            stockDate: values.entryDate,
            currency: values.currency
              ? {
                  code: values.currency,
                }
              : undefined,
            odometer: {
              units: values.odometerUnit,
              value: values.odometer,
              odometerOnApplication: values.odometerOnApplication,
            },
            price: {
              basePrice: values.basePrice,
              lessTaxes: values.priceLessTaxes,
              retail: values.price,
              retailPlusTax: values.retailPricePlusTax,
              standInValue: values.standInValue,
              trade: values.trade,
              netPrice: values.netPrice,
              priceOnApplication: values.priceOnApplication,
            },
            registrationPlate: values.regPlate,
            syndicationType: values.syndicationType as SyndicationType,
            visibility: values.visibility as ListingVisibility,
            internalComments: values.internalComments,
            publicComments: values.publicComments,
          },
        };
      });

      navigate(`/${brand}/dealers/${dealer}/vehicles/add/${nextStep}`);
    },
    (values) => {
      const {
        listingStatus,
        price,
        priceOnApplication,
        odometerOnApplication,
        odometer,
        odometerUnit,
        currency,
      } = values;

      let priceError: DecoderError | undefined = undefined;
      let currencyError: DecoderError | undefined = undefined;

      if (listingStatus === ListingStatus.ForSale && !priceOnApplication) {
        if (!price) {
          priceError = {
            id: 'INVALID_PRICE',
            text: 'The price is mandatory',
            field: 'price',
          };
        }
        if (!currency) {
          currencyError = {
            id: 'INVALID_CURRENCY',
            text: 'The currency is mandatory',
            field: 'currency',
          };
        }
      }

      let odometerError: DecoderError | undefined = undefined;
      let odometerUnitError: DecoderError | undefined = undefined;

      if (!odometerOnApplication) {
        if (!odometer) {
          odometerError = {
            id: 'INVALID_ODOMETER',
            text: 'The odometer is mandatory',
            field: 'odometer',
          };
        }

        if (!odometerUnit) {
          odometerUnitError = {
            id: 'INVALID_ODOMETER_UNIT',
            text: 'The odometer unit is mandatory',
            field: 'odometerUnit',
          };
        }
      }

      let saveVehicleRuleError: DecoderError | undefined = undefined;

      //validate brand rules
      if (!!brandConfig?.saveVehicleRule) {
        saveVehicleRuleError = validateSaveVehicleRule(
          values,
          brandConfig.saveVehicleRule,
        );
      }

      const errors = [
        priceError,
        currencyError,
        odometerError,
        odometerUnitError,
        saveVehicleRuleError,
      ].filter(Boolean);

      if (errors.length > 0) {
        return error(errors as DecoderError[]);
      }

      return ok(values);
    },
    [brandConfig, currentDealer, currentBrand, nextStep],
  );

  const [formState, formBind] = form;

  const contentItems: ContentItem[] = [
    {
      text: t('pages.vehiclePage.details.listing'),
      hash: 'listing',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.price'),
      hash: 'price',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.comments.publicComments'),
      hash: 'publicComments',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.comments.internalComments'),
      hash: 'internalComments',
      sectionElementRef: useRef(null),
    },
  ];

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const resetForm = useCallback(() => {
    formBind.reset({
      basePrice: '',
      bodyStyle: '',
      brand: '',
      capacity: '',
      collection: '',
      currency: currentDealer?.currency,
      drive: '',
      engineType: '',
      entryDate: '',
      exteriorColour: '',
      german25aTaxSalesDisclaimer: '',
      vatQualifying: '',
      priceExcludesVat: '',
      interiorColour: '',
      internalComments: '',
      listingStatus: '',
      model: '',
      modelId: '',
      modelYear: '',
      netPrice: '',
      odometer: '',
      odometerOnApplication: '',
      odometerUnit: currentDealer?.odometerUnit,
      price: '',
      priceLessTaxes: '',
      priceOnApplication: '',
      publicComments: '',
      registrationDate: '',
      regPlate: '',
      retailPricePlusTax: '',
      secondaryExteriorColour: '',
      secondaryInteriorColour: '',
      standInValue: '',
      syndicationType: '',
      trade: '',
      transmission: '',
      variant: '',
      vehicle: undefined,
      vin: '',
      visibility: '',
      dealerCountry: currentDealer?.countryCode,
    });
  }, [currentDealer, formBind]);

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [Object.values(formState.errors).some((x) => !!x?.length), isDirty];
  }, [formState]);

  const handleCancel = useCallback(() => {
    resetForm();
    setStepperSate((state) => ({
      ...state,
      detailsFormState: undefined,
    }));
    toggleResetConfirm();
    navigate(
      `/${brand}/dealers/${dealer}/vehicles/add${
        stepperState.temporaryVin ? '/manually' : ''
      }`,
    );
  }, [brand, dealer, navigate, resetForm, setStepperSate]);

  useEffect(() => {
    if (formState.submitError) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [formState.submitError, showMessage, t]);

  useEffect(() => {
    if (!currentBrand) {
      return;
    }

    const registrationDate =
      stepperState.detailsFormState?.registrationDate ||
      stepperState.currentListing?.vehicle?.registrationDate;

    formBind.reset({
      //Vehicle
      brand: currentBrand,
      modelId:
        stepperState.detailsFormState?.modelId ||
        stepperState.currentListing?.vehicle?.model.id,

      //Key information
      vin:
        stepperState.detailsFormState?.vin ||
        stepperState.currentListing?.vehicle?.vin,
      model:
        stepperState.detailsFormState?.model ||
        String(stepperState.currentListing?.vehicle?.model?.id || ''),
      variant:
        stepperState.detailsFormState?.variant ||
        stepperState.currentListing?.vehicle?.variant ||
        '',
      bodyStyle:
        stepperState.detailsFormState?.bodyStyle ||
        stepperState.currentListing?.vehicle?.bodyStyle?.name ||
        '',
      drive:
        stepperState.detailsFormState?.drive ||
        stepperState.currentListing?.vehicle?.handDrive ||
        '',
      collection: '',
      registrationDate: registrationDate
        ? DateTime.fromJSDate(registrationDate).toISODate()
        : '',
      modelYear:
        stepperState.detailsFormState?.modelYear ||
        stepperState.currentListing?.vehicle?.modelYear ||
        '',

      //Engine
      engineType:
        stepperState.detailsFormState?.engineType ||
        stepperState.currentListing?.vehicle?.engine?.description ||
        '',
      capacity:
        stepperState.detailsFormState?.capacity ||
        stepperState.currentListing?.vehicle?.engine?.capacity ||
        '',
      transmission:
        stepperState.detailsFormState?.transmission ||
        String(stepperState.currentListing?.vehicle?.transmission?.id || ''),

      //Price
      basePrice: String(stepperState.detailsFormState?.basePrice || ''),
      currency: currentDealer?.currency || '',
      price: String(stepperState.detailsFormState?.price || ''),
      trade: String(stepperState.detailsFormState?.trade || ''),
      standInValue: stepperState.detailsFormState?.standInValue || '',
      priceLessTaxes: String(
        stepperState.detailsFormState?.priceLessTaxes || '',
      ),
      retailPricePlusTax: String(
        stepperState.detailsFormState?.retailPricePlusTax || '',
      ),
      netPrice: String(stepperState.detailsFormState?.netPrice || ''),
      german25aTaxSalesDisclaimer:
        stepperState.detailsFormState?.german25aTaxSalesDisclaimer || YesNo.No,
      priceOnApplication:
        stepperState.detailsFormState?.priceOnApplication || false,
      vatQualifying: stepperState.detailsFormState?.vatQualifying || YesNo.No,
      priceExcludesVat:
        stepperState.detailsFormState?.priceExcludesVat || YesNo.No,

      //Listing
      entryDate: DateTime.now().toISODate(),
      odometer: stepperState.detailsFormState?.odometer || '',
      odometerUnit:
        stepperState.detailsFormState?.odometerUnit ||
        currentDealer?.odometerUnit ||
        '',
      listingStatus:
        stepperState.detailsFormState?.listingStatus || ListingStatus.ForSale,
      regPlate: stepperState.detailsFormState?.regPlate || '',
      visibility:
        stepperState.detailsFormState?.visibility || ListingVisibility.Visible,
      syndicationType: stepperState.detailsFormState?.syndicationType || '',
      odometerOnApplication:
        stepperState.detailsFormState?.odometerOnApplication || false,

      //Colour
      exteriorColour:
        stepperState.detailsFormState?.exteriorColour ||
        stepperState.currentListing?.vehicle?.appearanceOptions
          ?.exteriorColour ||
        '',
      interiorColour:
        stepperState.detailsFormState?.interiorColour ||
        stepperState.currentListing?.vehicle?.appearanceOptions
          ?.interiorColour ||
        '',
      secondaryExteriorColour:
        stepperState.detailsFormState?.secondaryExteriorColour ||
        stepperState.currentListing?.vehicle?.appearanceOptions
          ?.secondaryExteriorColour ||
        '',
      secondaryInteriorColour:
        stepperState.detailsFormState?.secondaryInteriorColour ||
        stepperState.currentListing?.vehicle?.appearanceOptions
          ?.secondaryInteriorColour ||
        '',

      //Comments
      internalComments: stepperState.detailsFormState?.internalComments || '',
      publicComments: stepperState.detailsFormState?.publicComments || '',

      //Vehicle
      vehicle:
        stepperState.detailsFormState?.vehicle ||
        stepperState.currentListing?.vehicle,

      //Dealer
      dealerCountry: currentDealer?.countryCode,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepperState, currentBrand]);

  useEffect(() => {
    if (!stepperState.currentListing?.vehicle) {
      //clear state and go to step 1
      setStepperSate(RESET);
      navigate('..', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, stepperState.currentListing]);

  return (
    <Form form={form} translations="pages.vehiclePage.details.form">
      <Stack
        direction={{
          sm: 'column-reverse',
          md: 'row',
        }}
        spacing={1}
      >
        <Stack flex={1}>
          <Section title={t('additionalDetails')}>
            {/* listing */}
            <div
              id={contentItems[0].hash}
              ref={
                contentItems[0]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <Listing
                actionType={ActionType.ADD}
                readonly={readonly}
                syndicationTypeOptions={brandSyndicationTypes}
                title={t('pages.vehiclePage.details.listing')}
              />
            </div>
            {/* Price */}
            <div
              id={contentItems[1].hash}
              ref={
                contentItems[1]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <Price
                readonly={readonly}
                title={t('pages.vehiclePage.details.price')}
              />
            </div>
            {/* Comments */}
            <div
              id={contentItems[2].hash}
              ref={
                contentItems[2]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <Comments
                label="publicComments"
                name="publicComments"
                readonly={readonly}
                rows={15}
                tabIndex={1}
                title={t('pages.vehiclePage.comments.publicComments')}
              />
            </div>
            <div
              id={contentItems[3].hash}
              ref={
                contentItems[3]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <Comments
                label="internalComments"
                name="internalComments"
                readonly={readonly}
                tabIndex={1}
                title={t('pages.vehiclePage.comments.internalComments')}
              />
            </div>
            {!readonly && (
              <Box
                bgcolor="background.paper"
                borderTop="1px solid #C4CDD5"
                bottom={0}
                p={1}
                position="sticky"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Button
                    disabled={formState.busy}
                    onClick={() => {
                      if (isDirty) {
                        toggleResetConfirm();
                        return;
                      }
                      navigate(
                        `/${brand}/dealers/${dealer}/vehicles/add${
                          stepperState.temporaryVin ? '/manually' : ''
                        }`,
                      );
                    }}
                    startIcon={<NavigateBefore />}
                    variant="outlined"
                  >
                    {t('vinNumber')}
                  </Button>
                  <LightTooltip title={hasErrors ? <FormErrorsSummary /> : ''}>
                    <span>
                      <LoadingButton
                        disabled={hasErrors}
                        endIcon={<NavigateNext />}
                        loading={formState.busy}
                        loadingPosition="end"
                        type="submit"
                        variant="contained"
                      >
                        {t(nextStep)}
                      </LoadingButton>
                    </span>
                  </LightTooltip>
                </Stack>
              </Box>
            )}
          </Section>
        </Stack>
        <div>
          <Section isSticky>
            <Stack>
              <TableOfContents
                items={contentItems}
                offsetPx={-80}
                scrollingElementSelector={MAIN_CONTAINER_ID}
              />
            </Stack>
          </Section>
        </div>
        <ConfirmModal
          onClose={toggleResetConfirm}
          onConfirm={handleCancel}
          open={resetConfirm}
          title={t('attention')}
        >
          <Typography variant="body1">{t('discard')}</Typography>
        </ConfirmModal>
      </Stack>
    </Form>
  );
};

export default DetailsStep;
