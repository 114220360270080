import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { odometerUnitOptions } from '../../../../../api/util/OdometerUnit.js';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import {
  ListingStatus,
  VehicleListing,
} from '../../../../../api/util/Vehicle.js';
import CellCheckboxInput from '../../../../common-ui/components/Cell/CellCheckboxInput.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import {
  CellDateInput,
  CellSelectInput,
  CellTextInput,
  useForm,
} from '../../../../common-ui/index.js';
import { Option } from '../../../../util/Option.js';
import { statusOptions } from '../../../../util/statusOptions.js';
import { visibilityOptions } from '../../../../util/visibility.js';
import { ActionType, Section, YesNo } from './types.js';

type ListingProps = Omit<Section, 'vehicleListing'> & {
  price?: VehicleListing['price'];
  syndicationTypeOptions?: Option[];
  options?: {
    disableRegistrationDate?: boolean;
  };
};

const Listing: FunctionComponent<ListingProps> = ({
  title,
  price,
  readonly,
  syndicationTypeOptions,
  options,
  actionType,
}) => {
  const [formState, formBind] = useForm();
  const handleChangeStatus = useCallback(
    (value: Option | undefined) => {
      if (value?.value !== ListingStatus.ForSale) {
        formBind.setValue('price', String(price?.retail || ''));
        formBind.setValue('priceLessTaxes', String(price?.lessTaxes || ''));
        formBind.setValue('basePrice', String(price?.basePrice || ''));
        formBind.setValue(
          'retailPricePlusTax',
          String(price?.retailPlusTax || ''),
        );
      }
    },
    [formBind, price],
  );

  const handleSyndicationTypeChange = useCallback(
    (value: Option | undefined) => {
      if (value?.value === SyndicationType.New) {
        const modelYear = Number(formState.values.modelYear);
        if (!modelYear) {
          return;
        }
        formBind.setValue('registrationDate', (preValue: string) => {
          return DateTime.fromISO(preValue)
            .set({ year: modelYear })
            .toISODate();
        });
      }
      setDefaultOOAandPOAValue(value?.value as SyndicationType);
    },
    [formBind, formState.values.modelYear],
  );

  const setDefaultOOAandPOAValue = (
    syndicationType: SyndicationType | undefined,
  ): void => {
    if (
      syndicationType !== undefined &&
      actionType !== undefined &&
      actionType === ActionType.ADD
    ) {
      formBind.setValue(
        'odometerOnApplication',
        syndicationType === SyndicationType.New,
      );
      formBind.setValue(
        'priceOnApplication',
        syndicationType === SyndicationType.New,
      );
    }
  };

  const [disabled, formattedOdometerValue] = useMemo(() => {
    const disabled = formState.values.odometerOnApplication;
    return [disabled, disabled ? '-' : undefined];
  }, [formState.values.odometerOnApplication]);

  return (
    <SectionItem title={title}>
      <Stack sx={sectionStyles.sectionContent}>
        <Stack flex={1}>
          <CellDateInput
            label="entryDate"
            name="entryDate"
            readonly={options?.disableRegistrationDate || readonly}
          />
          <CellSelectInput
            label="listingStatus"
            name="listingStatus"
            onChange={handleChangeStatus}
            options={statusOptions}
            readonly={readonly}
          />
          <CellSelectInput
            label="visibility"
            name="visibility"
            options={visibilityOptions}
            readonly={readonly}
          />
          <CellTextInput
            label="Registration plate"
            name="regPlate"
            readonly={readonly}
          />
        </Stack>
        <Stack flex={1}>
          <CellCheckboxInput
            formattedValue={
              formState.values.odometerOnApplication ? YesNo.Yes : YesNo.No
            }
            label="odometerOnApplication"
            name="odometerOnApplication"
            readonly={
              (disabled && !formState.values.odometerOnApplication) || readonly
            }
          />
          <CellSelectInput
            formattedValue={formattedOdometerValue}
            label="odometerUnit"
            name="odometerUnit"
            options={odometerUnitOptions}
            readonly={disabled || readonly}
            required={!formState.values.odometerOnApplication}
          />
          <CellTextInput
            formattedValue={formattedOdometerValue}
            label="Odometer"
            maxLength={10}
            name="odometer"
            readonly={disabled || readonly}
            required={!formState.values.odometerOnApplication}
          />
          <CellSelectInput
            label="syndicationType"
            name="syndicationType"
            onChange={handleSyndicationTypeChange}
            options={syndicationTypeOptions || []}
            readonly={readonly}
            required
          />
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Listing;
