import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleImage } from '../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import { UpdateUndefinedFields } from '../../../../api/VehicleListingService/updateVehicleListing/UpdateVehicleListingRequest.js';
import { VehicleListingForm } from '../../../../api/util/Vehicle.js';
import {
  VehicleMedia,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import AccordionExpandIcon from '../../../common-ui/components/AccordionExpandIcon.js';
import SectionItem from '../../../common-ui/components/SectionItem.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  ErrorState,
  TextInput,
  useMessageStore,
} from '../../../common-ui/index.js';
import { isValidUrl } from '../../../common-ui/validation/validUrl.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { sx } from '../../../util/sx.js';
import { useUpdateVehicleListing } from '../../hooks/mutations/useUpdateVehicleListing.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import AccordionHeader from '../VehicleMedia/AccordionHeader.js';
import ImageAccordion from '../VehicleMedia/ImageAccordion.js';
import VinImageAccordion from '../VehicleMedia/VinImageAccordion.js';

export const MAX_FILE_SIZE_MB = 40;

const styles = sx({
  accordionSummary: {
    padding: 0,
  },
  accordionContent: {
    px: 0,
  },
  accordionContainer: {
    border: 1,
    borderWidth: '1px 0',
    borderColor: '#E7EAEE',
    my: 3,
  },
  vehicleImageContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'flex-start',
  },
});

export interface OnChangeFileProps {
  file: File | null;
  position?: number;
}

interface VehicleMediaProps {
  vehicleListing: VehicleListingForm;
  readonly?: boolean;
  onSave?: (vehicleMedia?: VehicleMedia[]) => void;
  onChangeYouTubeUrl?: (youTubeUrl?: string, hasError?: boolean) => void;
  onChangeImages?: (simpleImages?: SimpleImage[]) => void;
  onChangeVinImage?: (vehicleMedia?: VehicleMedia) => void;
  actions?: ReactNode;
  options?: { expandedGroup?: VehicleMediaType[] };
  hideSaveButton?: boolean;
}

const VehicleMediaSection: FunctionComponent<VehicleMediaProps> = ({
  vehicleListing,
  onSave,
  onChangeYouTubeUrl,
  onChangeImages,
  onChangeVinImage,
  options,
  actions,
  readonly,
  hideSaveButton,
}) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const [mediaToDisplay, setMediaToDisplay] = useState<SimpleImage[]>([]);
  const [expanded, setExpanded] = useState<VehicleMediaType[]>([]);
  const [youTubeVideoUrl, setYouTubeVideoUrl] = useState<string>();
  const [youTubeUrlError, setYouTubeUrlError] = useState<boolean>(false);
  const [mediaUpdateResult, setMediaUpdateResult] = useAsyncState();
  const [media, setMedia] = useState<VehicleMedia[]>();
  const { mutate: updateVehicleListing, isPending } = useUpdateVehicleListing({
    onSettled: (data, error) => {
      if (!data || !!error) {
        return;
      }
      setMedia(data.vehicleMedia);

      onSave && onSave(data.vehicleMedia);
    },
  });

  const contentItems: ContentItem[] = [
    {
      text: t('photos'),
      hash: 'photos',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.media.video'),
      hash: 'video',
      sectionElementRef: useRef(null),
    },
  ];

  const handleVinImageSave = useCallback(
    (vehicleMedia: VehicleMedia[]) => {
      setMediaUpdateResult(async () => {
        if (!vehicleMedia || !vehicleListing.id) {
          return;
        }

        try {
          const newVehicleMedia = await api.media.updateVehicleMedia({
            vehicleId: vehicleListing.id,
            vehicleMedia,
          });
          showMessage({
            severity: 'success',
            text: t('successMessage'),
            dismissible: true,
          });
          onSave && onSave(newVehicleMedia);
          setMedia(vehicleMedia);
        } catch (error) {
          console.error(error);
          showMessage({
            severity: 'error',
            text: t('errorOccurredMessage'),
            dismissible: true,
          });
        }
      });
    },
    [
      api.media,
      onSave,
      setMediaUpdateResult,
      showMessage,
      t,
      vehicleListing.id,
    ],
  );

  const handleMediaSave = useCallback(() => {
    setMediaUpdateResult(async () => {
      if (!mediaToDisplay || !vehicleListing.id) {
        return;
      }

      try {
        const newVehicleListing = await api.media.reorderMedia({
          vehicleId: vehicleListing.id,
          reorderedImages: mediaToDisplay,
        });
        showMessage({
          severity: 'success',
          text: t('successMessage'),
          dismissible: true,
        });
        onSave && onSave(newVehicleListing.vehicleMedia);
        setMedia(newVehicleListing.vehicleMedia);
      } catch (error) {
        console.error(error);
        showMessage({
          severity: 'error',
          text: t('errorOccurredMessage'),
          dismissible: true,
        });
      }
    });
  }, [
    api.media,
    mediaToDisplay,
    onSave,
    setMediaUpdateResult,
    showMessage,
    t,
    vehicleListing.id,
  ]);

  const handleYoutubeLinkChange = useCallback(
    (value: string) => {
      setYouTubeVideoUrl(value);
      // we check if youTubeVideo url is a valid youtube url
      const youTubeUrlRegex =
        /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
      const youTubeUrlError =
        value !== '' && (!youTubeUrlRegex.test(value) || !isValidUrl(value).ok);

      setYouTubeUrlError(youTubeUrlError);
      onChangeYouTubeUrl && onChangeYouTubeUrl(value, youTubeUrlError);
    },
    [onChangeYouTubeUrl],
  );

  const handleSave = useCallback(() => {
    if (youTubeUrlError) {
      showMessage({
        severity: 'error',
        text: t('pages.vehiclePage.media.invalidYouTubeUrl'),
        dismissible: true,
      });
      return;
    }

    if (vehicleListing.id) {
      updateVehicleListing({
        youTubeLink: youTubeVideoUrl,
        id: vehicleListing.id,
        brand: vehicleListing.vehicle.brand,
        options: {
          updateUndefinedFields: [UpdateUndefinedFields.youTubeLink],
        },
      });
    }
  }, [
    showMessage,
    t,
    updateVehicleListing,
    vehicleListing.id,
    vehicleListing.vehicle.brand,
    youTubeUrlError,
    youTubeVideoUrl,
  ]);

  useEffect(() => {
    setYouTubeVideoUrl(vehicleListing.youTubeLink);
    setExpanded(options?.expandedGroup || []);
    setMedia(vehicleListing.vehicleMedia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleListing]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section title={t('media')}>
          <Stack sx={styles.accordionContainer}>
            <div
              id={contentItems[0].hash}
              ref={
                contentItems[0]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <ImageAccordion
                defaultExpanded={expanded.includes(VehicleMediaType.Image)}
                hideSaveButton={hideSaveButton}
                loading={mediaUpdateResult.loading}
                onChange={(simpleImages) => {
                  setMediaToDisplay(simpleImages || []);
                  onChangeImages && onChangeImages(simpleImages || []);
                }}
                onDeleteMedia={(newMedia?: VehicleMedia[]) =>
                  setMedia(newMedia)
                }
                onMediaSave={handleMediaSave}
                readonly={readonly}
                required
                vehicle={vehicleListing.vehicle}
                vehicleMedia={media}
                vinMd5Hash={vehicleListing.vinMd5Hash}
              />
              <VinImageAccordion
                defaultExpanded={expanded.includes(VehicleMediaType.Vin)}
                hideSaveButton={hideSaveButton}
                onChange={(vinMedia) => {
                  onChangeVinImage && onChangeVinImage(vinMedia);
                }}
                onMediaSave={handleVinImageSave}
                readonly={readonly}
                vehicle={vehicleListing.vehicle}
                vehicleMedia={media}
                vinMd5Hash={vehicleListing.vinMd5Hash}
              />
              <Accordion>
                <AccordionSummary
                  expandIcon={<AccordionExpandIcon />}
                  sx={styles.accordionSummary}
                >
                  <AccordionHeader
                    hasError={youTubeUrlError}
                    isComplete={!!youTubeVideoUrl}
                    title={t('pages.vehiclePage.media.video')}
                  />
                </AccordionSummary>
                <AccordionDetails sx={styles.accordionContent}>
                  <Stack spacing={3}>
                    <SectionItem
                      title={t('pages.vehiclePage.media.youTubeVideoLinkTitle')}
                    >
                      <Stack flex={1}>
                        <TextInput
                          disabled={readonly}
                          error={youTubeUrlError}
                          id="youtube-video"
                          onValueChange={handleYoutubeLinkChange}
                          value={youTubeVideoUrl}
                        />
                        {youTubeUrlError && (
                          <ErrorState
                            error={t(
                              'pages.vehiclePage.media.invalidYouTubeUrl',
                            )}
                            hideTitle
                          />
                        )}
                      </Stack>
                    </SectionItem>
                  </Stack>
                  {!readonly && !hideSaveButton && (
                    <Box m={1} marginLeft="auto">
                      <Stack direction="row" justifyContent="end" spacing={1}>
                        <LoadingButton
                          endIcon={<Save />}
                          loading={isPending}
                          loadingPosition="end"
                          onClick={handleSave}
                          sx={{
                            marginLeft: 2,
                          }}
                          variant="contained"
                        >
                          {t('save')}
                        </LoadingButton>
                      </Stack>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          </Stack>
          {actions && actions}
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
    </Stack>
  );
};

export default VehicleMediaSection;
